import { Thinkeo, ThinkeoError } from "@thinkeo.io/thinkeo-sdk"
import { toast } from "svelte-sonner"
import type { EventHandler } from "svelte/elements"

export function thinkeoErrorHandler(error: ThinkeoError) {
  //TODO(samuel): Handle the translation of of the key here
  toast.error(`An error occurred: ${error.message || error}`)
  console.error("An error occurred on the client side:", error)
}

export function globalErrorHandler(error: Error | any) {
  if (error instanceof Error) {
    toast.error(`An unexpected error happened: ${error.message}`)
  } else {
    toast.error(`An unexpected error happened`)
  }
  console.error("An unexpected error happened:", error)
}

export function errorHandler(errorEvent: ThinkeoError | any) {
  const { error, message } = errorEvent
  if (error instanceof ThinkeoError) {
    thinkeoErrorHandler(error)
  } else {
    toast.error(`An unexpected error happened ${message}`)
    console.error("An unexpected error happened:", message, error)
  }
}

export function errorHandlerRejection(event: PromiseRejectionEvent) {
  const { reason } = event

  if (reason instanceof ThinkeoError) {
    thinkeoErrorHandler(reason)
  } else {
    globalErrorHandler(reason)
  }
}
